import React, { createContext, useReducer, Dispatch } from 'react'
import { shoppingCartReducer, ShoppingCartActions, Cart } from './reducers/ShoppingCartReducer'
import { State as ShoppingCartState } from './reducers/ShoppingCartReducer'
// inject i18next throughout the application
import '../i18n.js'
import { getTotalAmount, getTotalQuantity, key } from './Store.helpers'

type InitialStateType = {
  shoppingCart: ShoppingCartState
}

/**
 * @summary gets the json data from localstorage if there already are items in the cart
 */
const initialStateFromLocalStorage = (): Cart | [] => {
  if (typeof window !== 'undefined') {
    try {
      const item = window.localStorage.getItem(key)
      return item ? JSON.parse(item) : []
    } catch (error) {
      // eslint-disable-next-line
      console.warn(error)
      return []
    }
  }
  return []
}

const initialState: InitialStateType = {
  shoppingCart: {
    cart: initialStateFromLocalStorage(),
    totalAmount: getTotalAmount(initialStateFromLocalStorage() || 0),
    showModal: false,
    totalQuantityOfProducts: getTotalQuantity(initialStateFromLocalStorage()) || 0
  }
}

const StoreContext = createContext<{
  state: InitialStateType
  dispatch: Dispatch<ShoppingCartActions>
}>({
  state: initialState,
  dispatch: () => null
})

const mainReducer = ({ shoppingCart }: InitialStateType, action: ShoppingCartActions) => ({
  shoppingCart: shoppingCartReducer(shoppingCart, action)
})

const StoreProvider: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer(mainReducer, initialState)

  return <StoreContext.Provider value={{ state, dispatch }}>{children}</StoreContext.Provider>
}

export { StoreProvider, StoreContext }
