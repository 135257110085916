import { Product, StoreProducts } from '../models/Products'

const pricePerType = (product: Product) => {
  switch (product.type) {
    case 'portion':
      return product.price_per_portion
    case 'product':
      return product.price_per_product
    case 'weight':
      return product.calculated_price
    default:
      return 0
  }
}

const round = (value: number) => {
  return parseFloat(value.toFixed(2))
}

const totalAmountFormatted = (totalAmount: number): string => {
  return round(19.0 - totalAmount)
    .toString()
    .replace('.', ',')
}

const getTotalAmount = (products: Array<StoreProducts>): number => {
  const arrayWithAmounts = products.map((product) => {
    const { quantity } = product
    const price = pricePerType(product)
    return parseFloat(price || '0') * quantity
  })
  const totalValue = arrayWithAmounts.reduce((a, c) => a + c, 0)
  return round(totalValue) || 0
}

const showModalCalculations = (current: number, newValue: number): boolean => {
  return current <= 19 && newValue >= 19
}

export const key = 'shoppingCart'

const setLocalStorage = (value: string) => {
  try {
    window.localStorage.setItem(key, value)
  } catch (error) {
    throw new Error(error)
  }
}

const getTotalQuantity = (cart: Array<StoreProducts>): number => {
  const quantities = cart.map((product) => product.quantity)
  const totalQuantity = quantities.reduce((a, c) => a + c, 0)
  return totalQuantity
}

export { pricePerType, round, getTotalAmount, showModalCalculations, totalAmountFormatted, setLocalStorage, getTotalQuantity }
