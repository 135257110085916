/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
import './src/styles/index.scss'

import React from 'react'

import { StoreProvider } from './src/store/context'
import Consent from './src/components/Consent/Consent'

export const onClientEntry = () => {
  // IntersectionObserver polyfill for gatsby-background-image (Safari, IE)
  if (typeof window.IntersectionObserver === `undefined`) {
    import(`intersection-observer`)
  }
}

export const wrapRootElement = ({ element }) => {
  return (
    <StoreProvider>
      <Consent>{element}</Consent>
    </StoreProvider>
  )
}
