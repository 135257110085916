import React, { useState, useEffect, ReactNode } from 'react'
import styles from './styles.module.scss'
import Helmet from 'react-helmet'
import Button from '../Button/Button'

const Consent: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [consent, setConsent] = useState<null | boolean>(null)

  const setStorageConsent = (value: string) => {
    try {
      typeof window !== 'undefined' && window.localStorage.setItem('consent', value)
    } catch (error) {
      throw new Error(error)
    }
  }

  const getLocalStorageValue = () => {
    if (typeof window !== 'undefined') {
      try {
        const item = window.localStorage.getItem('consent')
        const parse = item && JSON.parse(item)
        setConsent(parse)
      } catch (error) {
        // eslint-disable-next-line
        console.warn(error)
      }
    }
  }
  useEffect(() => {
    getLocalStorageValue()
  }, [])
  const handleClick = () => {
    setStorageConsent('true')
    return setConsent(true)
  }

  const disable = () => {
    setStorageConsent('false')
    return setConsent(false)
  }
  return (
    <>
      <Helmet>
        {consent && (
          <script>
            {`
        (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','GTM-K3JL6RB');
        `}
          </script>
        )}
      </Helmet>
      {children}
      {consent === null && (
        <div className={styles.consent}>
          <div className={styles.wrapper}>
            <p>
              Vi använder cookies på vår webbsida för att analysera hur den används. Genom att godkänna accepterar du att cookies
              används.&nbsp;
              <a
                className={styles.readmore}
                href="https://www.orkla.com/legal/se/sekretess-och-integritet/"
                target="_blank"
                rel="noreferrer noopener"
              >
                Läs mer här
              </a>
            </p>
            <div className={styles.buttons}>
              <button className={styles.disable} onClick={disable}>
                Stäng av
              </button>
              <Button className={styles.consentButton} color="green" onClick={handleClick}>
                Jag godkänner
              </Button>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default Consent
