// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-resultat-tsx": () => import("./../../../src/pages/resultat.tsx" /* webpackChunkName: "component---src-pages-resultat-tsx" */),
  "component---src-pages-varukorg-tsx": () => import("./../../../src/pages/varukorg.tsx" /* webpackChunkName: "component---src-pages-varukorg-tsx" */),
  "component---src-templates-budget-tsx": () => import("./../../../src/templates/budget.tsx" /* webpackChunkName: "component---src-templates-budget-tsx" */),
  "component---src-templates-faq-tsx": () => import("./../../../src/templates/faq.tsx" /* webpackChunkName: "component---src-templates-faq-tsx" */),
  "component---src-templates-index-tsx": () => import("./../../../src/templates/index.tsx" /* webpackChunkName: "component---src-templates-index-tsx" */),
  "component---src-templates-page-tsx": () => import("./../../../src/templates/page.tsx" /* webpackChunkName: "component---src-templates-page-tsx" */),
  "component---src-templates-scale-info-tsx": () => import("./../../../src/templates/scaleInfo.tsx" /* webpackChunkName: "component---src-templates-scale-info-tsx" */),
  "component---src-templates-shop-tsx": () => import("./../../../src/templates/shop.tsx" /* webpackChunkName: "component---src-templates-shop-tsx" */)
}

