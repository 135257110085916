import classNames from 'classnames'
import React, { ButtonHTMLAttributes } from 'react'
import styles from './styles.module.scss'

interface Props extends ButtonHTMLAttributes<HTMLButtonElement> {
  color: 'white' | 'green'
}

const Button: React.FC<Props> = ({ children, className, color, ...rest }) => {
  return (
    <button className={classNames(styles.button, styles[color], className)} {...rest}>
      {children}
    </button>
  )
}

export default React.memo(Button)
