import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import translationSV from './locales/sv/translationSV.json'

const resources = {
  sv: { translation: translationSV }
}

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: 'sv',
    fallbackLng: 'sv',
    debug: true,

    interpolation: {
      escapeValue: false,
    }
  });


export default i18n;
