import { StoreProducts } from '../../models/Products'
import { ActionMap } from '../models/Reducers'
import { getTotalAmount, getTotalQuantity, setLocalStorage, showModalCalculations } from '../Store.helpers'

export enum Types {
  ADD_PRODUCT = 'ADD_PRODUCT',
  UPDATE_QUANTITY = 'UPDATE_QUANTITY',
  REMOVE_PRODUCT = 'REMOVE_PRODUCT'
}

export enum Actions {
  ADD_PRODUCT = 'ADD_PRODUCT',
  UPDATE_QUANTITY = 'UPDATE_QUANTITY',
  REMOVE_PRODUCT = 'REMOVE_PRODUCT'
}

type ShoppingCartPayload = {
  [Types.ADD_PRODUCT]: {
    product: StoreProducts
  }
  [Types.UPDATE_QUANTITY]: {
    product: StoreProducts
  }
  [Types.REMOVE_PRODUCT]: {
    product: StoreProducts
  }
}
export interface Action {
  type: Actions
}

export type Cart = Array<StoreProducts> | []

export interface State {
  cart: Array<StoreProducts>
  totalAmount: number
  showModal: boolean
  totalQuantityOfProducts: number
}

export type ShoppingCartActions = ActionMap<ShoppingCartPayload>[keyof ActionMap<ShoppingCartPayload>]

/**
 * @summary adds or updates a product to the cart
 * @param state - current state
 * @param action - action containing new item to be added
 * @returns - updated state object
 */
const addProductToCart = (state: State, action: ShoppingCartActions): State => {
  // if there are items in the cart
  if (state.cart !== null) {
    // new array with current items and new item
    const productArray: Array<StoreProducts> = [...state.cart, action.payload.product]
    // filter out the old version of the new item
    const removeCurrentItem = productArray.filter((item) => item.id !== action.payload.product.id && item)

    // if the quantity of the new item is 0 remove it from the array
    const removeZeroQuantities =
      action.payload.product.quantity !== 0 ? [...removeCurrentItem, action.payload.product] : [...removeCurrentItem]

    // pass it to the state and localStorage
    setLocalStorage(JSON.stringify(removeZeroQuantities))
    const newTotalAmount = getTotalAmount(removeZeroQuantities)
    const showModal = showModalCalculations(state.totalAmount, newTotalAmount)
    const totalQuantityOfProducts = getTotalQuantity(removeZeroQuantities)

    return { ...state, cart: removeZeroQuantities, totalAmount: newTotalAmount, showModal, totalQuantityOfProducts }
  }
  // if there are no items in the cart
  setLocalStorage(JSON.stringify([action.payload.product]))
  const showModal = showModalCalculations(state.totalAmount, getTotalAmount([action.payload.product]))
  const totalQuantityOfProducts = getTotalQuantity([action.payload.product])

  return {
    ...state,
    cart: [action.payload.product],
    totalAmount: getTotalAmount([action.payload.product]),
    showModal,
    totalQuantityOfProducts
  }
}

/**
 * @summary updates the quantity of a item in the cart
 * @param state - current state
 * @param action - action with updated quantity
 * @returns - updated state object
 */
const updateQuantity = (state: State, action: ShoppingCartActions): State => {
  const { product } = action.payload
  const updatedCart = [...state.cart]
  const updateItemIndex = updatedCart.findIndex((item) => item.id === product.id)

  if (product.quantity === 0) {
    updatedCart.splice(updateItemIndex, 1)
  } else {
    updatedCart[updateItemIndex].quantity = product.quantity
  }

  setLocalStorage(JSON.stringify(updatedCart))
  const showModal = showModalCalculations(state.totalAmount, getTotalAmount(updatedCart))
  const totalQuantityOfProducts = getTotalQuantity(updatedCart)
  return { ...state, cart: updatedCart, totalAmount: getTotalAmount(updatedCart), showModal, totalQuantityOfProducts }
}

/**
 * @summary removes a item from the cart
 * @param state - current state
 * @param action - action containing item to be removed
 * @returns - updated state
 */

const removeProduct = (state: State, action: ShoppingCartActions): State => {
  const { product } = action.payload

  const cart = [...state.cart]
  const removedItem = cart.filter((item) => item.id !== product.id)
  const totalQuantityOfProducts = getTotalQuantity(removedItem)
  const totalAmount = getTotalAmount(removedItem)

  setLocalStorage(JSON.stringify(removedItem))
  return { ...state, cart: removedItem, totalAmount, totalQuantityOfProducts }
}

export const shoppingCartReducer = (state: State, action: ShoppingCartActions): State => {
  switch (action.type) {
    case Types.ADD_PRODUCT:
      return addProductToCart(state, action)
    case Types.UPDATE_QUANTITY:
      return updateQuantity(state, action)
    case Types.REMOVE_PRODUCT:
      return removeProduct(state, action)
    default:
      return state
  }
}
